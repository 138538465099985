import clsx from 'clsx'
import React from 'react'
import styles from '../app/styles/Nav.module.sass'
import type { MenuResult } from '../queries/MenuQuery'
import { Arrow } from './Arrow.svg'
import { Container } from './Container'
import { Facebook } from './Facebook.svg'
import { Instagram } from './Instagram.svg'
import { Link } from './Link'
import { Linkedin } from './Linkedin.svg'
import { Logo } from './Logo.svg'
import { Youtube } from './Youtube.svg'

type NavProps = {
	menu: MenuResult
	instagram?: string
	facebook?: string
	youtube?: string
	linkedin?: string
	invert: boolean
	active?: string
	positionsCount?: number
}

export function Nav(props: NavProps) {
	const [menuOpenState, setMenuOpenState] = React.useState<boolean>(false)

	const toggle: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = React.useCallback(
		(e) => {
			e.preventDefault()

			setMenuOpenState((old) => !old)
		},
		[]
	)
	return (
		<nav
			className={clsx(styles.nav, menuOpenState && styles.active, props.invert && styles.invert)}>
			<Container>
				<div className={styles.inner}>
					<div className={styles.left}>
						{menuOpenState ? (
							<a href="/">
								<Logo />
							</a>
						) : (
							<a href="/">
								<Logo />
							</a>
						)}
						<ul className={styles.menu}>
							{props.menu.items.map((item) => {
								return (
									<li
										key={item.id}
										className={clsx(
											props.active === item.link?.internalLink?.page?.id && styles.current
										)}>
										<Link
											link={item.link}
											nonExternal
											suffix={
												item.link?.title?.includes('pozice')
													? props.positionsCount?.toString()
													: undefined
											}
										/>
									</li>
								)
							})}
						</ul>
					</div>
					<div className={styles.right}>
						<ul>
							<li>
								<a href={props.youtube}>
									<Youtube />
								</a>
							</li>
							<li>
								<a href={props.instagram}>
									<Instagram />
								</a>
							</li>
							<li>
								<a href={props.facebook}>
									<Facebook />
								</a>
							</li>
							<li>
								<a href={props.linkedin}>
									<Linkedin />
								</a>
							</li>
						</ul>
						<a href="https://www.subterra.cz/">Subterra.cz</a>
					</div>
					<button className={styles.close} onClick={toggle}>
						<Arrow />
						<span>ZPĚT</span>
					</button>
				</div>
			</Container>
			<button className={styles.open} onClick={toggle}>
				<span>MENU</span>
			</button>
		</nav>
	)
}
